body {
    -webkit-tap-highlight-color: transparent;
}

img {
    pointer-events: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
textarea:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px #F6F6F6 inset !important;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    background-color: white;
    border: 1.6px solid #FF9F68;
}

.slick-slider .slick-slide img {
    padding: 0;
    display: flex;
    justify-content: flex-start;
}
